import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Spin } from 'antd';
import { CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import Amplify, {
  Analytics, Auth, DataStore, Storage,
} from 'aws-amplify';
import { navigate } from 'gatsby';
import { getUserRole, logout } from '../Shared/Services/AuthService';
import { fetchUserBySub, updateUserBySub } from '../DataStore/Services';
import { User } from '../../models/index';
import awsconfig from '../../backendConfigProvider';
import * as styles from '../../styles/admin/Authenticate.module.scss';
import changeSync from '../../services/DataStoreConfigure';
import { isBDMUser, postEvent } from '../../services/utils';

dayjs.extend(isBetween);

Analytics.autoTrack('session', {
  enable: true,
  provider: 'AWSPinpoint',
});
Amplify.configure(awsconfig);
Storage.configure({ level: 'protected' });

const mapObj = (f) => (obj) => Object.keys(obj)
  .reduce((acc, key) => ({ ...acc, [key]: f(obj[key]) }), {});
const toArrayOfStrings = (value) => [`${value}`];
const mapToArrayOfStrings = mapObj(toArrayOfStrings);

const Authenticate = (Page) => {
  const AuthenticatePage = (props) => {
    const [dSUser, setDsUser] = useState(null);
    const [authUser, setAuthUser] = useState(null);
    const [identityProviderClient, setIdentityProviderClient] = useState(null);
    /**
     * check if both arrays have same content
     *
     * @param {*} array1
     * @param {*} array2
     * @return {*}
     */
    const checkIsArraySame = (array1, array2) => {
      if (array1.length !== array2.length) {
        return false;
      }
      for (let i = 0; i < array1.length; i += 1) {
        if (!array2.includes(array1[i])) {
          return false;
        }
      }
      return true;
    };
    const fetchUserFromDataStore = async (sub, activeId) => {
      const currentUser = await fetchUserBySub(sub, activeId);
      // if dSUser is present and the role is diff then refresh the token
      if (dSUser && currentUser) {
        if (!checkIsArraySame(dSUser?.roles, currentUser?.roles)) {
          setDsUser(null);
          // refresh token and proceed to ensure permissions are reflected
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          const refreshTokenPromise = new Promise((resolve, reject) => {
            cognitoUser.refreshSession(currentSession.refreshToken, (err, data) => {
              if (err) {
                reject(err);
              }
              if (data) {
                resolve(data);
              }
            });
          });
          const data = await refreshTokenPromise;
          const user = { ...authUser };
          if (user) {
            user.signInUserSession = { ...data };
            setAuthUser(user);
          }
        }
      }
      if (currentUser) {
        setDsUser(currentUser);
      } else {
        navigate('/workspaces/');
        console.log('user not found in DS', sub);
      }
    };

    const triggerIdentifyEvent = async (currentUser) => {
      const phone = currentUser?.phoneNumber;
      const title = currentUser?.designation;
      const eventPayload = {
        userId: currentUser?.id,
        enterpriseId: currentUser?.enterpriseID,
        originalTimestamp: dayjs().toISOString(),
        sentAt: dayjs().toISOString(),
        traits: {
          email: currentUser?.email,
          firstName: currentUser?.firstName,
          lastName: currentUser?.lastName,
          name: currentUser?.name,
          roles: currentUser?.roles,
          ...(phone && { phone }),
          ...(title && { title }),
          createdAt: currentUser?.createdAt,
          lastActive: dayjs().toISOString(),
          exclude: isBDMUser(currentUser?.email),
        },
      };
      postEvent(eventPayload, '/identify');
    };

    /**
     * update lastActive for user
     *
     * @param {*} sub
     */
    const updateLastActiveForUser = async (sub, id) => {
      const currentUser = await fetchUserBySub(sub, id);
      let lastActive = currentUser?.lastActive;
      const createdAt = currentUser?.createdAt;
      const isLastActiveWithinSameDay = lastActive
        ? dayjs(lastActive).isBetween(dayjs().toISOString(),
          dayjs().subtract(1, 'day').toISOString()) : null;
      const createdAtDiffInHours = createdAt ? dayjs().diff(createdAt, 'hour') : null;
      // Datastore update
      if (!lastActive || !isLastActiveWithinSameDay) {
        lastActive = dayjs().toISOString();
        updateUserBySub(sub, { id, lastActive });
      }
      // identify API update
      // Temporary hack to avoid concurrent identify call on signup
      if (createdAt && createdAtDiffInHours > 6 && !isLastActiveWithinSameDay) {
        triggerIdentifyEvent(currentUser);
      }
    };

    const loadDependencies = async () => {
      try {
        const currentAuthUser = await Auth.currentAuthenticatedUser();
        const sub = currentAuthUser?.attributes?.sub;
        const activeId = currentAuthUser.attributes?.['custom:active_user'];
        await changeSync(sub);

        const userAttributes = mapToArrayOfStrings(currentAuthUser.attributes);
        Analytics.updateEndpoint({
          address: currentAuthUser.attributes.email,
          channelType: 'EMAIL',
          optOut: 'NONE',
          userId: currentAuthUser.attributes.sub,
          userAttributes,
        }).catch((e) => {
          console.log(e);
        });

        const idPClient = new CognitoIdentityProviderClient({
          region: awsconfig.aws_cognito_region,
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: awsconfig.aws_cognito_region }),
            identityPoolId: awsconfig.aws_cognito_identity_pool_id,
            logins: { [`cognito-idp.us-east-1.amazonaws.com/${awsconfig.aws_user_pools_id}`]: currentAuthUser.signInUserSession.idToken.getJwtToken() },
          }),
        });

        await fetchUserFromDataStore(sub, activeId);
        setAuthUser(currentAuthUser);
        updateLastActiveForUser(sub, activeId);
        setIdentityProviderClient(idPClient);
      } catch (error) {
        console.log('Auth failed, redirecting to login', error);
        logout(false);
      }
    };

    useEffect(() => {
      loadDependencies();
    }, []);

    useEffect(async () => {
      let currentUserObserver;
      if (authUser?.attributes?.sub) {
        // setup current user observer
        currentUserObserver = DataStore.observe(User).subscribe((msg) => {
          if (msg.element.sub === authUser.attributes.sub && ['INSERT', 'UPDATE', 'DELETE'].includes(msg.opType)) {
            fetchUserFromDataStore(authUser.attributes.sub, authUser?.attributes?.['custom:active_user']);
          }
        });
      }
      return () => {
        if (currentUserObserver) {
          currentUserObserver.unsubscribe();
        }
      };
    }, [authUser]);

    useEffect(() => {
      if (dSUser) {
        if (dSUser?.status !== 'CONFIRMED' || !dSUser?.enabled) {
          logout(false);
        }
      }
    }, [dSUser]);
    const { location, data } = props;
    const userProperties = {};
    if (dSUser) {
      userProperties.role = getUserRole(dSUser.roles);
    }

    return (
      <>
        {authUser
      && dSUser?.enabled
      && dSUser?.status === 'CONFIRMED'
      && identityProviderClient
          ? (
            <Page
              identityProviderClient={identityProviderClient}
              loggedInUserDatastoreData={dSUser}
              location={location}
              user={authUser}
              data={data}
            />
          )
          : <div className={styles.spinnerWrapper}><Spin size="large" tip="Loading..." /></div>}
      </>
    );
  };
  AuthenticatePage.propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    data: PropTypes.objectOf(PropTypes.any),
  };

  AuthenticatePage.defaultProps = {
    data: {},
  };
  return AuthenticatePage;
};
export default Authenticate;
